<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
    </base-header>

    <b-container fluid class="mt--7">
      <b-row>
        <b-col>
          <b-overlay :show="loading" rounded="md">
            <b-card no-body>
              <b-card-header class="border-1 d-flex justify-content-between">
                <h3 class="mb-0">作成：{{ workflow.name }}</h3>
                <template v-if="workflow.help_document">
                  <div class="mt--1">
                    <a :href="workflow.help_document" target="_blank">
                      <div
                        :id="`help-workflow-${workflow.id}`"
                        class="icon-shape text-white rounded-circle shadow bg-dark icon-action"
                      >
                        <i class="fas fa-question"></i>
                      </div>
                    </a>
                  </div>
                  <b-tooltip :target="`help-workflow-${workflow.id}`" triggers="hover">
                    マニュアル
                  </b-tooltip>
                </template>
              </b-card-header>

              <b-card-body>
                <b-form @submit.prevent>
                  <h6 class="heading-small text-muted mb-4">所属情報</h6>
                  <div class="pl-lg-4">
                    <b-row v-if="workflow.code === '10'">
                      <!-- 通勤変更 -->
                      <b-col md="6" lg="6">
                        <base-select
                          label="所属案件"
                          v-model="project_id" 
                          :options="project_choice"
                        >
                        </base-select>
                      </b-col>
                      <b-col md="6" lg="6">
                        <base-select
                          label="所属部署"
                          v-model="org_id"
                          :options="getOrganizationchoices"
                        >
                        </base-select>
                      </b-col>
                      <b-col sm="12" md="6" lg="6">
                        <b-form-group>
                          <b-form-radio-group
                            v-model="attendance_type"
                            name="attendance-type"
                            @change="changeAttendanceType"
                            :disabled="is_same_as_prev"
                          >
                            <b-form-radio value="0">現場出勤</b-form-radio>
                            <b-form-radio value="1">在宅勤務</b-form-radio>
                          </b-form-radio-group>
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" md="6" lg="6">
                        <b-form-checkbox
                          v-model="is_same_as_prev"
                          @change="getPrevMonthCommuting"
                        >
                          先月と一緒
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                    <b-row v-else-if="workflow.code === '11'">
                      <!-- 経費精算 -->
                      <ExpenseWidget
                        :initial="{org_id, project_id}"
                        :org_choice="getOrganizationchoices"
                        :project_choice="project_choice"
                        @input="updateByWidget"
                      />
                    </b-row>
                    <b-row v-else-if="workflow.code === '12'">
                      <!-- 勤怠登録 -->
                      <b-col md="6" lg="6">
                        <label class="form-control-label">所属部署</label>
                        <div class="pl-lg-4">{{ full_org_name}}</div>
                      </b-col>
                    </b-row>
                    <b-row v-else>
                      <b-col md="6" lg="6">
                        <base-select
                          label="所属部署"
                          v-model="org_id"
                          :options="getOrganizationchoices"
                        >
                        </base-select>
                      </b-col>
                    </b-row>
                  </div>
                  <template v-if="workflow.code === '03'">
                    <!-- ビザ変更 -->
                    <hr class="my-4">
                    <h6 class="heading-small text-muted mb-4">現在の在留カード情報</h6>
                    <div class="pl-lg-4">
                      <VisaInfoWidget/>
                    </div>
                  </template>
                  <template v-else-if="workflow.code === '20'">
                    <!-- 有休申請 -->
                    <hr class="my-4">
                    <h6 class="heading-small text-muted mb-4">現在の有休情報</h6>
                    <div class="pl-lg-4">
                      <paid-vacation-widget />
                    </div>
                  </template>
                  <template v-if="workflow.comment">
                    <hr class="my-4">
                    <h6 class="heading-small text-muted mb-4">注意事項</h6>
                    <div class="pl-lg-4">
                      <div v-text="workflow.comment" style="white-space:pre-wrap;"></div>
                    </div>
                  </template>
                  <hr class="my-4">
                  <h6 class="heading-small text-muted mb-4">タスク基本情報</h6>
                  <!-- 勤怠登録 -->
                  <div class="pl-lg-4" v-if="workflow.code === '12'">
                    <attendance-form
                            :schema="fields_schema"
                            :layout="fields_layout"
                            :value="fields"
                            action="create"
                            ref="basci_info_form"
                    />
                  </div>
                  <!-- 資材申請 -->
                  <div class="pl-lg-4" v-else-if="workflow.code === '13'">
                    <assets-form
                            :schema="fields_schema"
                            :layout="fields_layout"
                            :value="fields"
                            :workflow_code="workflow.code"
                            @inventory="handleInventory"
                            ref="basci_info_form"
                    />
                  </div>
                  <div class="pl-lg-4" v-else>
                    <base-form
                      :schema="fields_schema"
                      :layout="fields_layout"
                      :value="fields"
                      @input="handleChange"
                      ref="basci_info_form"
                    />
                  </div>
                </b-form>
              </b-card-body>
              <b-card-footer>
                <b-row>
                  <b-col cols="12" class="d-flex justify-content-between">
                    <span>
                      <b-button variant="light" @click="createTask(false)">一時保存</b-button>
                      <b-button variant="primary" @click="createTask(true)">申請</b-button>
                    </span>
                    <span>
                      <b-button variant="dark" :to="{ name: 'task-workflows' }">戻る</b-button>
                    </span>
                  </b-col>
                </b-row>
              </b-card-footer>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>

      <b-modal
          id="working_time_confirm_modal"
          scrollable
          noCloseOnBackdrop
          size="md"
          @ok="handleWorkingTimeConfirm"
      >
          <template v-slot:modal-title>
              <b-icon-exclamation-triangle-fill class="mr-2" variant="warning" font-scale="2.5"></b-icon-exclamation-triangle-fill>
              勤怠登録
          </template>
          <p class="my-4">
              {{ working_time_confirm_message }}
          </p>
      </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Toast from '../../components/Toast';
import { ajax, common, config, constant } from '../../utils';
import { helper } from '../common';
import ExpenseWidget from './widgets/expense';
import VisaInfoWidget from './widgets/curr_visa';
import PaidVacationWidget from './widgets/paid_vacation.vue';

export default {
  components: {
    ExpenseWidget,
    VisaInfoWidget,
    PaidVacationWidget,
  },
  data() {
    return {
      loading: true,
      workflow: {},
      project_id: null,
      organization_id: null,
      fields_schema: [],
      fields_layout: [],
      fields: {},
      residence: {},
      attendance_type: "0",
      is_same_as_prev: false,
      is_assets_inventory: true,
        working_time_confirm_message: null,
    }
  },
  computed: {
    ...mapState('account', ['user', 'organizations', 'getOrganizationchoices']),
    ...mapGetters('account', ['full_org_name', 'getOrganizationchoices']),
    project_choice: function() {
      if (this.user && this.user.projects) {
        return this.user.projects.map(p => ({ value: p.project_id, text: p.project_name }));
      } else {
        return [];
      }
    },
    project_name: function() {
      const project = this.project_choice.find(p => p.value === this.project_id)
      if (this.project_id && project) {
        return project.text;
      } else {
        return null;
      }
    },
    org_id: {
      get: function() {
        if (!this.organization_id) {
          return this.user && this.user.organizations && this.user.organizations.length > 0 ? this.user.organizations[this.user.organizations.length - 1].org_id : null;
        }
        return this.organization_id;
      },
      set: function(newValue) {
        this.organization_id = newValue;
      }
    },
    org_name: function() {
      const org = this.getOrganizationchoices.find(o => o.value === this.org_id)
      if (org) {
        return org.text;
      } else {
        return null;
      }
    },
  },
  created: function () {
    this.getWorkflowDetail(this.$route.params.id);
  },
  updated: function() {
    document.title = `${this.workflow.name} | ${common.getPageName(this.$route.name)}`;
  },
  methods: {
    ...mapActions('account', ['getStatistics']),
    updateByWidget(data) {
      this.organization_id = data.org_id;
      this.project_id = data.project_id;
    },
    changeAttendanceType(attendance_type) {
      if (this.workflow.code === '10') {
        // 通勤変更
        if (attendance_type === '1') {
          // 自宅
          this.fields.home_station = '自宅';
          this.fields.work_station = '自宅';
          this.fields.amount = 0;
        } else {
          this.fields.home_station = null;
          this.fields.work_station = null;
          this.fields.amount = null;
        }
        this.fields_schema.map(item => {
          // if (['home_station', 'work_station'].indexOf(item.name) >= 0) {
          //   item.type = attendance_type === '0' ? "autocomplete" : 'string';
          // }
          if (['home_station', 'work_station', 'amount'].indexOf(item.name) >= 0) {
            item.read_only = attendance_type === '1';
          }
          return item;
        });
      }
    },
      getWorkflowDetail(id) {
      const vm = this;
      ajax.fetchGet(common.formatStr(config.api.task.workflow_detail, id)).then(workflowData => {
        ajax.fetchGet(common.formatStr(config.api.task.workflow_form, id)).then(data => {
            vm.workflow = workflowData;
          vm.fields_schema = helper.get_schema_from_fields(data.fields);
                    // vm.fields_schema.map(item => {
          //   if (['home_station', 'work_station'].indexOf(item.name) >= 0) {
          //     item.type = "autocomplete";
          //     item.data_source = vm.searchStation;
          //   }
          //   return item;
          // });
          vm.fields_layout = helper.get_layout_from_fields(data.fields);
          vm.fields = helper.get_value_from_fields(data.fields);
          //勤怠申請
          if (vm.workflow.code === '12') {
            //時間設定
            vm.fields.work_start_time = '09:30';
            vm.fields.work_end_time = '18:30';
            vm.fields.early_start_overtime = '05:00';
            vm.fields.early_end_overtime = '09:30';
            vm.fields.start_overtime = '18:30';
            vm.fields.end_overtime = '22:00';
            vm.fields.late_start_time = '22:00';
            vm.fields.late_end_time = '05:00';
            vm.fields.lunch_start_time = '12:00';
            vm.fields.lunch_end_time = '13:00';
            //時間設定（現場）
            vm.fields.work_start_time_2 = '09:30';
            vm.fields.work_end_time_2 = '18:30';
            vm.fields.early_start_time_2 = '05:00';
            vm.fields.early_end_time_2 = '09:30';
            vm.fields.start_overtime_2 = '18:30';
            vm.fields.end_overtime_2 = '22:00';
            vm.fields.late_start_time_2 = '22:00';
            vm.fields.late_end_time_2 = '05:00';
            vm.fields.lunch_start_time_2 = '12:00';
            vm.fields.lunch_end_time_2 = '13:00';
          }
        }).catch(errors => {
          Toast.errorFromApi(errors)
        }).finally(() => {
          vm.loading = false;
        });
      }).catch(errors => {
        console.error(errors);
        const status = errors?.status_code;
        if (status !== 401) {
          Toast.errorFromApi(errors);
        }
      });
    },
    getPrevMonthCommuting() {
      const vm = this;
      if (this.is_same_as_prev) {
        common.loading();
        ajax.fetchGet(config.api.employee.prev_month_commuting).then(data => {
          if (common.isEmpty(data)) {
            Toast.error(constant.ERROR.NO_PREV_COMMUTING);
            vm.is_same_as_prev = false;
          } else {
            data.map(item => {
              if (item.name === 'start_date') {
                const new_start_date = common.addMonths(item.value, 1);
                item.value = common.formatDate(new_start_date, 'YYYY-MM-DD');
              }
              return item;
            })
            vm.fields_schema.map(item => (item.read_only = true));
            vm.fields = helper.get_value_from_fields(data);
          }
        }).finally(() => {
          common.loaded();
        });
      } else {
        this.fields_schema.map(item => (item.read_only = false));
        this.fields = {
          amount: null,
          attachment_name: undefined,
          commuter_pass_image: undefined,
          home_address: null,
          home_station: null,
          start_date: null,
          work_address: null,
          work_station: null,
        }
        this.changeAttendanceType(this.attendance_type);
      }
    },
    async createTask(submit) {
      const vm = this;
      const isValid = await this.$refs.basci_info_form.validate();
      if (isValid) {
        if (submit === false || this.fields['working_time_confirm'] === true || confirm(constant.CONFIRM.CREATE_TASK)) {
          common.loading();
          //資材申請
          let workflow_code = vm.$route.params.id;
          if (submit === true && this.workflow.code === '13' && this.is_assets_inventory === false) {
            workflow_code = '14';
          }
          ajax.fetchPost(config.api.task.task_list, {
            workflow: workflow_code,
            employee: vm.user.me.employee,
            project_id: vm.project_id,
            project_name: vm.project_name,
            org_id: vm.org_id,
            org_name: vm.org_name,
            status: submit === true ? '10' : '01',
            fields: vm.fields,
          }).then(() => {
            Toast.success(constant.SUCCESS.SAVED)
            vm.getStatistics();
            vm.$router.push({ name: 'tasks-unresolved' });
          }).catch(errors => {
              if ('working_time_confirm' in errors) {
                  vm.working_time_confirm_message = errors.message;
                  this.$bvModal.show('working_time_confirm_modal');
              } else {
                  Toast.errorFromApi(errors)
                  this.fields['working_time_confirm'] = false;
              }
          }).finally(() => {
            common.loaded();
          });
        }
      }
    },
    // searchStation(query) {
    //   return ajax.fetchGet(common.formatStr(config.api.third_part.search_station, query)).then(data => data.map(item => ({
    //     value: item.station_code,
    //     text: `${item.station_name}, ${item.line_name}, ${item.pref_name}`
    //   })));
    // },
    handleChange(val) {
      if (this.workflow.code === "20") {
        // 有休申請
        const { start_date, days, is_start_half } = val;
        if (start_date && days) {
          ajax.fetchGet(`${config.api.employee.paid_vacation_end_date}?start_date=${start_date}&days=${days}&is_start_half=${is_start_half}`).then(_data => {
            this.fields = Object.assign({}, val, { end_date: common.formatDate(_data.end_date, "YYYY-MM-DD") })
          }).catch(errors => {
            Toast.errorFromApi(errors);
            this.fields = Object.assign({}, val, { end_date: null })
          })
        }
      }
    },
    handleInventory(inventory) {
      //資材申請
      if (this.workflow.code === "13") {
        this.is_assets_inventory = inventory;
      }
    },
      handleWorkingTimeConfirm() {
          this.fields['working_time_confirm'] = true;
          this.createTask(true);
          this.$bvModal.hide('working_time_confirm_modal');
      },
  },
}
</script>
