<template>
  <b-table-simple>
    <b-tbody>
      <b-tr>
        <b-th class="text-right" style="width: 35%">住所</b-th>
        <b-td>{{residence.address}}</b-td>
      </b-tr>
      <b-tr>
        <b-th class="text-right">在留カード番号</b-th>
        <b-td>{{residence.residence_no}}</b-td>
      </b-tr>
      <b-tr>
        <b-th class="text-right">在留資格</b-th>
        <b-td>{{residence_type}}</b-td>
      </b-tr>
      <b-tr>
        <b-th class="text-right">在留満了日</b-th>
        <b-td>{{residence.expired_date}}</b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>
<script>
import { mapState } from 'vuex'
import { ajax, choice, common, config } from '../../../utils';

export default {
  data() {
    return {
      residence: {},
    }
  },
  watch: {
    user: function() {
      this.get_curr_residence_status();
    }
  },
  computed: {
    ...mapState('account', ['user']),
    employee: function() {
      if (this.user && this.user.me) {
        return this.user.me.employee;
      } else {
        return null;
      }
    },
    residence_type: function() {
      return common.getChoiceText(this.residence.residence_type, choice.residence);
    },
  },
  mounted() {
    this.get_curr_residence_status();
  },
  methods: {
    get_curr_residence_status() {
      const vm = this;
      if (!this.employee) {
        return;
      }
      ajax.fetchGet(common.formatStr(config.api.employee.residence_status, this.employee)).then(data => {
        vm.residence = data;
      }).finally(() => {
      });
    },
  },
}
</script>